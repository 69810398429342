var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"110px"}},[_c('el-form-item',{attrs:{"label":"员工昵称："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入昵称"},model:{value:(_vm.searchForm.nickname),callback:function ($$v) {_vm.$set(_vm.searchForm, "nickname", $$v)},expression:"searchForm.nickname"}})],1),_c('el-form-item',{attrs:{"label":"员工手机号："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入昵称"},model:{value:(_vm.searchForm.mobile),callback:function ($$v) {_vm.$set(_vm.searchForm, "mobile", $$v)},expression:"searchForm.mobile"}})],1),_c('el-form-item',{attrs:{"label":"加入时间："}},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","size":"small"},model:{value:(_vm.searchForm.time),callback:function ($$v) {_vm.$set(_vm.searchForm, "time", $$v)},expression:"searchForm.time"}})],1),_c('el-form-item',{attrs:{"label":" ","label-width":"20px"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.getList()}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.cancelSearch()}}},[_vm._v("清空搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.dataList,"header-cell-style":{ 'background-color': '#F8F9FA' }}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"prop":"name","label":"用户信息","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('UserInfo',{attrs:{"name":row.nickname,"avatar":row.avatar}})]}}])}),_c('el-table-column',{attrs:{"prop":"mobile","label":"手机号","align":"center"}}),_c('el-table-column',{attrs:{"label":"员工身份","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.shareholder ? '区域经理' : '员工')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"代理区域","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.shareholder ? row.area_name : '--')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"累计积分","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(Number(row.commission_amount) + Number(row.goods_commission_amount))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"can_withdraw_amount","label":"现有积分数","align":"center"}}),_c('el-table-column',{attrs:{"prop":"user_count","label":"累计邀请员工","align":"center"}}),_c('el-table-column',{attrs:{"label":"注册时间","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getDateformat(row.create_time))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.updateStartFn(row)}}},[_vm._v("修改身份")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total,"page":_vm.page,"pageNum":_vm.rows},on:{"updatePageNum":_vm.updateData}}),_c('el-dialog',{attrs:{"title":"修改身份","visible":_vm.updateDialogFlag,"width":"600px"},on:{"update:visible":function($event){_vm.updateDialogFlag=$event}}},[_c('el-form',{ref:"form1",attrs:{"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"员工昵称："}},[_vm._v(" "+_vm._s(_vm.updateItem.nickname))]),_c('el-form-item',{attrs:{"label":"员工身份："}},[_vm._v("普通员工 ")]),_c('el-form-item',{attrs:{"label":"修改身份为："}},[_vm._v(" 区域经理")]),_c('el-form-item',{attrs:{"label":"代理区域："}},[_c('el-cascader',{attrs:{"props":{ value: 'id', label: 'name', children: '_child' },"placeholder":"请选择或搜索","clearable":"","filterable":"","options":_vm.cityList},model:{value:(_vm.chooseCity),callback:function ($$v) {_vm.chooseCity=$$v},expression:"chooseCity"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.updateDialogFlag = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.sureUpdate()}}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }